<template>
	<svg class="icon"><use :xlink:href="'#' + icon"></use></svg>
</template>
<script>
export default {
	props: ["icon"],
	data(){
		return {}
	}
}
</script>
