import Vue from 'vue'
//import VueMask from 'v-mask'
import { Buffer } from 'buffer'
import ApexCharts from 'vue-apexcharts'
import vuetify from  './plugins/vuetify'
import App from './App.vue'
import router from './plugins/router'
import { VueMaskDirective } from 'v-mask'

import axios from 'axios'


// @ts-ignore
window.Buffer = Buffer
axios.defaults.baseURL = "https://panel.instream.audio"

Vue.directive('mask', VueMaskDirective)
window["siteId"] = "instream.panel"
window["service"] = {
	base: "https://panel.instream.audio",
	ui: "https://panel.instream.audio/instream.panel/ui",
	api:"https://panel.instream.audio/instream.panel/api"
}

Vue.config.productionTip = false


Vue.use(ApexCharts)
//Vue.use(VueMask)
Vue.component('apexchart', ApexCharts)

// @ts-ignore
let params:any = {
	vuetify,
	router,
	render: h => h(App)
}
new Vue(params).$mount('#app')
